// // import Script from "next/script";

// import { logger } from "@/utils/logging/log-util";
// import { useEffect } from "react";

// const AmazonAds = () => {
//   useEffect(() => {
//     logger.info("setting apstag default");
//     apstag.init({
//       pubID: "de3fe0da-157b-4cc2-b632-8a1b54f47196",
//       adServer: "googletag",
//     });
//   }, []);

//   return (
//     <>
//       <script async
//         type="text/javascript"
//         dangerouslySetInnerHTML={{
//           __html: `!function(TAM,a,p,s,t,A,g){if(a[TAM])return;function q(c,r){a[TAM]._Q.push([c,r])}a[TAM]={init:function(){q("i",arguments)},fetchBids:function(){q("f",arguments)},setDisplayBids:function(){},_Q:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)[0];g.parentNode.insertBefore(A,g)}("apstag",window,document,"script","//c.amazon-adsystem.com/aax2/apstag.js")`,
//         }}
//       ></script>
//     </>
//   );
// };

// export default AmazonAds;
// ----------------------------------------------------------------------------
import { useEffect } from "react";
import { logger } from "@/utils/logging/log-util";

const AmazonAds = () => {
  useEffect(() => {
    const loadAmazonAds = () => {
      logger.info("setting apstag default");

      if (!window.apstag) {
        const script = document.createElement("script");
        script.src = "https://c.amazon-adsystem.com/aax2/apstag.js";
        script.defer = true;
        script.onload = () => {
          window.apstag.init({
            pubID: "de3fe0da-157b-4cc2-b632-8a1b54f47196",
            adServer: "googletag",
          });
        };
        document.body.appendChild(script);
      }
    };

    // Load ads after a delay
    const timeout = setTimeout(() => {
      loadAmazonAds();
    }, 1500); // 1 second delay

    return () => clearTimeout(timeout);
  }, []);

  return null;
};

export default AmazonAds;